import React, { useEffect } from 'react'
import Best from '../components/Best'
import Landing from '../components/Landing'
import AboutUs from '../components/AboutUs'
import Gallery from '../components/Gallery'
import Contacts from '../components/Contacts'

function Home() {
  useEffect(() => {
    document.body.style.backgroundImage = 'url(https://firebasestorage.googleapis.com/v0/b/terrasa-f2de3.appspot.com/o/%D0%A4%D0%BE%D0%BD%D1%8B%2Fbackground1.jpg?alt=media&token=b6bbb224-b420-4121-83ee-a282e73973dc)'
  },[])
  return (
    <div className='home'>
        <Landing/>
        <Best/>
        <AboutUs/>
        <Gallery/>
        <Contacts/>
    </div>
  )
}

export default Home