import React from 'react'

function Meal({meal}) {
  return (
    <div className='meal'>
      <div className='meal-text'>
        <h2 className='meal-title'>{meal.title.toUpperCase()}</h2>
        <h3>{meal.description}</h3>
      </div>
      <h3 className='meal-price'>{meal.price}</h3>
    </div>
  )
}

export default Meal