import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Item(props) {
  useEffect(() => {
    AOS.init({duration:2000})
  }, [])

  return (
    <a className='item-card' data-aos="flip-left" href={`/menu/${props.title}`}>
      <img className="item-img" src={`https://firebasestorage.googleapis.com/v0/b/terrasa-f2de3.appspot.com/o/Категории%2F${props.title.split(' ')[0]}.png?alt=media&token=4456dc87-7120-42ee-8da0-b3850d832a6a`} alt={props.title}/>
      <h2 className='item-title'>{props.title.toUpperCase()}</h2>
      <a className='item-link' href={`/menu/${props.title}`}>СМОТРЕТЬ В МЕНЮ</a>
    </a>
  )
}

export default Item
