import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Meal from '../components/Items/meal/Meal'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Loading from '../components/Loading'

function ItemPage() {
    const item = useParams().item
    const [meals, setMeals] = useState([])

    useEffect(() => {
        document.body.style.backgroundImage = "url(https://firebasestorage.googleapis.com/v0/b/terrasa-f2de3.appspot.com/o/%D0%A4%D0%BE%D0%BD%D1%8B%2FPaper.png?alt=media&token=84066e83-0af0-49e0-848e-6c061fb59afa)"
        AOS.init({duration:2000})
        fetch(`https://terrasa-server.onrender.com/menu/${item}`)
        .then(res => res.json())
        .then(data => setMeals(data))
      })

  return (<>
      { meals.data?.length > 0 ?  
    <div className='item-page'>
      <div className='subtitle'>
        <div className='line' data-aos='fade-right'/>
          <h2 data-aos='zoom-out'className='itempage-title'>{item.toUpperCase()}</h2>
        <div className='line' data-aos='fade-left'/>
      </div>

      <div className='meals' data-aos='zoom-in'>
        {meals?.data.map(meal => <Meal meal={meal}/>)}
      </div>
    </div>
    :<Loading/>
    }
  </>
  )
}

export default ItemPage