import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Landing() {
  useEffect(() => {
    AOS.init({duration: 2000})
  },[])
  return (
    <div className='landing'>
        <div className='landing-wrapper'>

          <div className='title' data-aos="fade-up">
            <h1>TERRASA</h1>

            <div className='subtitle'>
              <div className='line' data-aos='fade-left'/>

              <h2>LOUNGE CAFE</h2>

              <div className='line' data-aos='fade-right'/>
            </div>

            <a href='/menu' className='book'><button id='title-but'>МЕНЮ</button></a>
            
          </div>
        </div>
    </div>
  )
}

export default Landing