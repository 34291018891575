import React, { useEffect } from 'react'
import Logo from '../assets/icons/logo.svg'
import InstLogo from '../assets/icons/Inst.svg'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Footer() {
    useEffect(() => {
        AOS.init({duration: 2000})
    },[])
  return (
    <div className='footer-wrapper' >
        <div className='footer-line' width="700vh" data-aos='fade-up'/>
        <div className='footer'>
            <a className='logo-wrapper'href='/home'>
                <a >
                    <img alt='logo' 
                    src={Logo} 
                    className='logo'
                    width={73}
                    />
                </a>

                <span className='name'>TERRASA</span>

            </a>

            <a className="designed" href='https://symmetry.uz/'>Designed by symmetry.uz</a>
            
            <a className='footer-inst' href="https://www.instagram.com/terrasa__chirchik/">
                <img className='inst-logo' src={InstLogo} alt='instagram'/>
            </a>
        </div>
    </div>
  )
}

export default Footer