import React, { useEffect } from 'react'
import Exterior from '../assets/images/exterior.png'
import Interior from '../assets/images/interior.png'
import AOS from 'aos'
import 'aos/dist/aos.css'

function AboutUs() {
    useEffect(() => {
        AOS.init({duration:2000})
    }, [])
  return (
    <div className='about-us' id='aboutUs'>
        <div className='about-text' data-aos="fade-right">
            <div className='about-title'>
                <h2>НАШЕ ЗАВЕДЕНИЕ</h2>
                <div className='line'/>
            </div>
            <div className='about-text'>
            может похвастаться уютной атмосферой, которая заставит вас чувствовать себя как дома. Каждая деталь, от теплого освещения до удобных сидений, была тщательно продумана для создания идеальной атмосферы. А еда? Ну, скажем так, это так же впечатляюще, как и окрестности. Приходите и убедитесь сами, почему Терраса быстро становится популярным местом как для гурманов, так и для тех, кто ищет комфорт.
            </div>
            <a href='tel:+998917787710' className='book'><button>ЗАБРОНИРОВАТЬ СТОЛИК</button></a>
        </div>
        <div className='about-pics' data-aos="fade-left">
            <img src={Exterior} className='exterior-img' alt='exterior'/>
            <div className='interior'>
                <img src={Interior} className='interior-img' alt='interior'/>
                
            </div>
            
        </div>
    </div>
  )
}

export default AboutUs