import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Header from "./components/Header";
import Home from "./Pages/Home";
import Menu from "./Pages/Menu";
import Footer from "./components/Footer";
import ItemPage from "./Pages/ItemPage";
import Helmet from "react-helmet"

function App() {

  return (
    <>
    <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>Terrasa - Лучший ресторан в Чирчике</title>
      <meta name="description"
  content="Добро пожаловать в Terrasa! Мы предлагаем лучшие блюда в Чирчике. Ознакомьтесь с нашим вкусным меню и насладитесь теплой и располагающей атмосферой. Закажите столик прямо сейчас!"/>
<meta name="keywords"
  content="ресторан в чирчике, стейки чирчик, доставка еды чирчик, терраса ресторан, чирчик, узбекистан"/>
<meta name="author" content="Terrasa"/>
<meta name="robots" content="index, follow"/>

<link rel="canonical" href="https://terrasa-chirchiq.uz/"/>

<meta property="og:title" content="Terrasa - Лучший ресторан в Чирчике"/>
<meta property="og:description"
  content="Добро пожаловать в Terrasa! Мы предлагаем лучшие блюда в Чирчике. Ознакомьтесь с нашим вкусным меню и насладитесь теплой и располагающей атмосферой. Закажите столик прямо сейчас!"/>
<meta property="og:image" content="https://terrasa-chirchiq.uz/og.jpg"/>
<meta property="og:url" content="https://terrasa-chirchiq.uz/"/>
<meta property="og:site_name" content="Terrasa"/>
<meta property="og:type" content="website"/>
<meta property="og:locale" content="ru_RU" />
<meta property="og:image:width" content="1200"/>
<meta property="og:image:height" content="630"/>

<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="terrasa-chirchiq.uz"/>
<meta property="twitter:url" content="https://terrasa-chirchiq.uz"/>
<meta name="twitter:title" content="Terrasa - Лучший ресторан в Чирчике"/>
<meta name="twitter:description"
  content="Добро пожаловать в Terrasa! Мы предлагаем лучшие блюда в Чирчике. Ознакомьтесь с нашим вкусным меню и насладитесь теплой и располагающей атмосферой. Закажите столик прямо сейчас!"/>
<meta name="twitter:image" content="https://terrasa-chirchiq.uz/og.jpg"/>
<script type="application/ld+json">
  {`
    "@context": "https://schema.org",
    "@type": "Restaurant",
    "name": "Terrasa",
    "image": "https://terrasa-chirchiq.uz/og.jpg",
    "@id": "",
    "url": "https://terrasa-chirchiq.uz/",
    "telephone": "+998917787710",
    "menu": "https://terrasa-chirchiq.uz/menu",
    "servesCuisine": "European",
    "acceptsReservations": "true",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "1A Lomonosov",
      "addressLocality": "Chirchik",
      "postalCode": "",
      "addressCountry": "UZ"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 41.469227,
      "longitude": 69.579747
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "sameAs": "https://www.instagram.com/terrasa_chirchik/"`
  }
    </script>
    </Helmet>

    <Router>
      <Header/>
      <Routes>
        <Route path='/home' element={<Home/>}/>
        <Route exact path='/menu' element={<Menu/>}/>
        <Route path='/menu/:item' element={<ItemPage/>}/>
        <Route path='*' element={<Navigate to='home'/>}/>
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
