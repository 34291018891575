import React, { useEffect, useState} from 'react'
import Item from '../components/Items/Item/Item'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Loading from '../components/Loading'

function Menu() {
  const [items, setItems] = useState([])

  useEffect(() => {
    document.body.style.backgroundImage = "url(https://firebasestorage.googleapis.com/v0/b/terrasa-f2de3.appspot.com/o/%D0%A4%D0%BE%D0%BD%D1%8B%2FPaper.png?alt=media&token=84066e83-0af0-49e0-848e-6c061fb59afa)"
    document.body.style.backgroundSize= 'cover';
    AOS.init({duration:2000})
    fetch('https://terrasa-server.onrender.com/menu')
    .then(res => res.json())
    .then(data => setItems(data))
  },[])

  return (
  <>
  { items.length > 0 ?  
    <div className='menu'>
      <div className='subtitle'>
        <div className='line' data-aos='fade-right'/>
          <h2 data-aos='zoom-out'>МЕНЮ</h2>
        <div className='line' data-aos='fade-left'/>
      </div>
      <div className='items'>
        {items.map( item => <Item title={item.title} key={item.title}/>) }
      </div>
    </div> 
    :<Loading/>

  }
  </>
  )
}

export default Menu