import React, { useEffect, useRef, useState } from 'react'
import Carousel from 'better-react-carousel'
import ArrowLeft from '../assets/icons/arrow-left.svg'
import ArrowRight from '../assets/icons/arrow-right.svg'
import AOS from 'aos'
import 'aos/dist/aos.css'



function SliderPrev() {
  useEffect(() => {
    AOS.init({duration:2000})
  })
  return (
      <img src={ArrowLeft} className='slider-but' id='slider-prev' data-aos="fade-right" alt='arrow left'/>
  )
}

function SliderNext() {
  return (
      <img src={ArrowRight} className='slider-but' id='clider-next' data-aos="fade-left" alt='arrow right'/>
  )
}



function Slider() {
    const [pics,setPics] = useState([])
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    useEffect(() => {
      fetch("https://terrasa-server.onrender.com/home").then(res => res.json()).then(data => setPics(data))
    },[])

    return (
          <Carousel style={{position: "relative",}} gap={50} cols={(windowSize.current[0] > 1500) ? 3 : 1} rows={1} loop arrowLeft={<SliderPrev/>} arrowRight={<SliderNext/>} autoplay={3000}>
              <Carousel.Item>
                <img className='slider-img' src={pics[0]?.src} alt='gallery'/>
              </Carousel.Item>
              <Carousel.Item>
                <img className='slider-img' src={pics[1]?.src} alt='gallery'/>
              </Carousel.Item>
              <Carousel.Item>
                <img className='slider-img' src={pics[2]?.src} alt='gallery'/>
              </Carousel.Item>
              <Carousel.Item>
                <img className='slider-img' src={pics[3]?.src} alt='gallery'/>
              </Carousel.Item>
              <Carousel.Item>
                <img className='slider-img' src={pics[4]?.src} alt='gallery'/>
              </Carousel.Item>
              <Carousel.Item>
                <img className='slider-img' src={pics[5]?.src} alt='gallery'/>
              </Carousel.Item>
          </Carousel>
      )
}

export default Slider