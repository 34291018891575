import React, { useEffect, useRef } from 'react'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import AOS from 'aos'
import 'aos/dist/aos.css'

function Contacts() {
    useEffect( () => {
        AOS.init({duration:2000})
    })
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <div className='contacts' id='contacts'>
        <div className='contacts-text' data-aos='fade-right'>
            <div>
                <h2>КОНТАКТЫ</h2>
                <div className='line'/>
            </div>

            <h3><a href='tel:+998917787710' className='phone-number'>+998 91 778 77 10</a></h3>

            <div className='working-days'>
                <h4>понедельник - четверг: 10:00 - 23:00</h4>
                <h4>пятница - воскресенье: 10:00 - 00:00</h4>
            </div>

            <a href='tel:+998917787710' className='book'><button>ЗАБРОНИРОВАТЬ СТОЛИК</button></a>
        </div>
        <div className='contacts-map' data-aos='fade-left'>
            <YMaps>
                <Map 
                defaultState={{ center: [41.469227, 69.579747], zoom: 16 }} 
                width={(windowSize.current[0] < 1024) ? "80vw" : "50vw"} 
                height="500px" 
                >
                     <Placemark geometry={[41.469227, 69.579747]}/>
                </Map>
            </YMaps>
            <span className='address'>Г. ЧИРЧИК УЛ. ЛОМОНОСОВА 1A, ОРИЕНТИР МУЗЫКАЛЬНАЯ ШКОЛА</span>
        </div>
    </div>
  )
}

export default Contacts