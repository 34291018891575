import React, { useEffect } from 'react'
import Slider from './Slider'
import Looper2 from '../assets/images/Looper-2.png'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Gallery() {
    useEffect( () => {
        AOS.init({duration:2000})
    })
  return (
    <div className='gallery'>
        <img src={Looper2} alt='looper' className='looper-2' data-aos="fade-left"/>
        <div className='subtitle' style={{marginBottom: "30px"}} data-aos="fade-down">
            <div className='line'/>
            <h2>ГАЛЕРЕЯ</h2>
            <div className='line'/>
        </div>
        <Slider data-aos='fade-down'/>
        <div>
          <a className='book' href='https://www.instagram.com/terrasa__chirchik/'>
            <button className='insta-but' data-aos='fade-down'>НАШ INSTAGRAM</button>
          </a>
        </div>
    </div>
  )
}

export default Gallery