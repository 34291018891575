import React, { useEffect } from 'react'
import Star from '../assets/images/star.png'
import Looper from '../assets/images/Looper-1.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Item from './Items/Item/Item'

const bestItems = ['салаты','стейки','пицца']

function Best() {
    useEffect(() => {
        AOS.init({duration:2000})
    },[])

  return (
    <div className='best'>
        <img 
        alt='star' 
        src={Star} 
        className='star' 
        data-aos='fade-left'
        />
        <img 
        alt='looper' 
        src={Looper} 
        className='looper' 
        data-aos='fade-right'
        />
        <div 
        className='subtitle'
        data-aos="fade-down"
        id='best-title'
        >
            <div className='line'data-aos="fade-left"/>

                <h2>ЛУЧШЕЕ</h2>

            <div className='line' data-aos="fade-right"/>

        </div>

        <div className='text' data-aos='fade-down'>
            Мы предоставляем вкуснейшие блюда из свежайших продуктов
        </div>

        <div className='best-items'>
            
            {bestItems.map( item => <Item title={item} key={item} />)}
        </div>
    </div>
  )
}

export default Best