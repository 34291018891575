import React, { useEffect } from 'react'
import Looper from '../assets/images/Looper-1.png'
import Cross from '../assets/icons/cross.svg'
import AOS from 'aos'
import 'aos/dist/aos.css'

function BurgerMenu({active, setActive}) {
    useEffect(() => {
        AOS.init({duration:2000})
    }, [])

  return (
    <>
    {
        active &&   
    <div className='burger-menu' data-aos='zoom-out'>
        <div className='blur' 
        onClick={() => setActive(false)}
        />

        <div className='burger-content' data-aos='fade-left'>
            <img src={Looper} className='burger-looper' alt='looper'/>
            <img src={Cross} className='burger-cross'
            onClick={() => setActive(false)}
            alt='cross'
            />
            <ul
            onClick={() => setActive(false)}
            >
                <li><a href='#home'>ГЛАВНАЯ</a></li>
                <li><a href='/menu'>МЕНЮ</a></li>
                <li><a href='#aboutUs'>О НАС</a></li>
                <li><a href='#contacts'>КОНТАКТЫ</a></li>
                <li><a href='tel:+998917787710' className='book'><button>ЗАБРОНИРОВАТЬ</button></a></li>
            </ul>
        </div>
    </div>
    }
    
    </>

  )
}

export default BurgerMenu