import React, { useEffect, useState } from 'react'
import Logo from '../assets/images/logo.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import BurgerMenu from './BurgerMenu'

function Header() {
    useEffect( () => {
        AOS.init({duration:2000})
    }, [])
  const [burgerActive, setBurgerActive] = useState(false)

  return (
        <>
        <div className='header' data-aos="fade-down">
            <a href='/home'>
                <img alt='logo' 
                src={Logo} 
                className='header-logo'
                />
            </a>

            <nav>
                <a href='/home'>ГЛАВНАЯ</a>
                <a href='/menu'>МЕНЮ</a>
                <a href='/home#aboutUs'>О НАС</a>
                <a href='/home#contacts'>КОНТАКТЫ</a>
            </nav>

            <a href='tel:+998917787710' className='book' id='header-book'><button>ЗАБРОНИРОВАТЬ</button></a>
            
            

            <div 
            className='burger-btn'
            onClick={() => {setBurgerActive(!burgerActive)}}
            >
                <span/>
            </div>
        </div>

        <BurgerMenu active = {burgerActive} setActive = {setBurgerActive}/>
    </>
  )
}

export default Header